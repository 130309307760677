.Slug input:-webkit-autofill,
.Slug input:-webkit-autofill:hover, 
.Slug input:-webkit-autofill:focus
.Slug textarea:-webkit-autofill,
.Slug textarea:-webkit-autofill:hover
.Slug textarea:-webkit-autofill:focus,
.Slug select:-webkit-autofill,
.Slug select:-webkit-autofill:hover,
.Slug select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: inherit;
  transition: background-color 5000s ease-in-out 0s;
}